import React from "react";
import { FaCheck } from "react-icons/fa";

const AffirmativeStatement = ({
  question,
  details,
}) => {
  return (
    <div className="flex">
      <FaCheck className="text-xl text-green-500 flex-shrink-0" />
      <div className="ml-3">
        <h3 className="font-semibold text-lg">{question}</h3>
        <p className="text-gray-500">{details}</p>
      </div>
    </div>
  );
};

export default AffirmativeStatement;
