import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import EssayList from "./EssayList";
import LinkButton from "./LinkButton";

const query = graphql`
  {
    allMdx(limit: 4, sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        excerpt
        id
        frontmatter {
          author
          slug
          date(formatString: "MMM Do, YYYY")
          categories
          timeToRead
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const LatestEssays = () => {
  const result = useStaticQuery(query);
  return (
    <>
      <h2 className="text-2xl font-extrabold mb-2 md:mb-0 mt-4 lg:mt-8 xl:mt-16">
        Latest Essays
      </h2>
      <EssayList essays={result.allMdx.nodes} />
      <LinkButton to="/essays" text="View all Essays" />
    </>
  );
};

export default LatestEssays;
