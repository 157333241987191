import React from "react";
import AffirmativeStatement from "./AffirmativeStatement";
import EmailSubscriptionWidget from "./EmailSubscriptionWidget";

const EmailLead = () => {
  return (
    <>
      <h2 className="text-2xl font-extrabold mb-4">
        Does that sound like you?
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <AffirmativeStatement
          question="I feel like I'm just a code monkey"
          details="who is currently mindlessly implementing Jira ticket BE-8712
              without understanding how that will help the business."
        />
        <AffirmativeStatement
          question="I feel stuck at my job"
          details="and I don't know how to make the next step in my career."
        />
        <AffirmativeStatement
          question="I want to become a technical leader"
          details="but I don’t know how to make the jump from senior engineer to tech lead or software architect."
        />
        <AffirmativeStatement
          question="I want to be the guy who can do things end-to-end and own the result"
          details="I want to develop marketing and sales as complementary skills to deliver something amazing."
        />
        <AffirmativeStatement
          question="I'm looking for a side-gig"
          details="but noone seems to care about the things I produce. I don’t know how I can create anything of value for others."
        />
      </div>
      <div className="grid grid-cols -6"></div>
      <EmailSubscriptionWidget className="sm:col-span-6 md:col-span-4"/>
    </>
  );
};

export default EmailLead;
