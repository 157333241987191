import React from "react";
import SocialLinks from "./SocialLinks";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <section className="grid grid-cols-1 mb-8 lg:grid-cols-3 lg:mb-16">
      <div className="self-center lg:col-span-2">
        <h1 className="text-5xl font-extrabold text-gray-900">
          Engineering <span className="text-blue-500">Business Value</span>
        </h1>
        <p className="text-4xl font-bold text-gray-700 mt-12 mb-4 max-w-3xl">
          Hi, I&apos;m{" "}
          <span className="font-extrabold text-gray-800">Jan&nbsp;</span>
          <span role="img" aria-label="waving hand">
            👋
          </span>
        </p>
        <p className="text-2xl text-gray-500 mb-4 mt-2 max-w-3xl">
          I am a{" "}
          <span className="text-blue-500 font-semibold">Engineering Lead</span>{" "}
          who writes about connecting the dots between&nbsp;
          <span className="text-gray-800 underline">
            people, software, marketing &amp; sales
          </span>
          &nbsp;to engineer business value.
        </p>
        <SocialLinks />
      </div>

      <StaticImage
        className="w-full rounded-lg mt-4 max-w-sm lg:mt-0 lg:col-span-1 lg:justify-self-end"
        src="../images/jan-goebel.jpg"
      />
    </section>
  );
};

export default Hero;
