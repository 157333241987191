import * as React from "react"
import EmailLead from "../components/EmailLead";
import Hero from "../components/Hero";
import LatestEssays from "../components/LatestEssays";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  return (
    <Layout>
     <Hero/>
     <EmailLead/>
     <LatestEssays />
    </Layout>
  )
}

export default IndexPage

export const Head = () => <SEO
  title="Home"
  description="Jan Goebel is an Engineering Lead who writes about how to engineer business value."
/>
